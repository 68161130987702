import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import { GoAppsComponent as GoApps, GoLanguageComponent as GoLanguage } from '@gv/ammo-grome';
import EmployeeAccount from '@/core/spe/employee-account/employee-account.vue';
import TranslationService from '@/locale/translation.service';
import LoginService from '@/account/login.service';

/*
 * @author ricardo.gaspar@grupo-valco.com
 */
@Component({
  components: {
    'employee-account': EmployeeAccount,
    'go-apps': GoApps,
    'go-language': GoLanguage,
  },
})
export default class AppBar extends Vue {
  @Inject('loginService')
  private loginService!: () => LoginService;

  @Inject('translationService')
  private translationService!: () => TranslationService;

  public currentLanguage = '';

  // eslint-disable-next-line
  private languages: { [k: string]: any } = this.$store.getters.languages;

  created() {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.setDocumentTitle();
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
        this.setDocumentTitle();
      }
    );
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  mounted() {
    this.setDocumentTitle();
  }

  @Emit('drawerClick')
  // eslint-disable-next-line
  drawerBubbleUp() {
  }

  @Emit('drawerRightClick')
  // eslint-disable-next-line
  drawerRightBubbleUp() {
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
    // SPE Begin vuetify internationalization
    this.$vuetify.lang.current = newLanguage.substring(0, 2);
    // SPE End vuetify internationalization
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public openLogin() {
    const next = '/auth';
    return this.$router.push(next);
  }

  public logout() {
    this.loginService().logout();
    this.$router.push({ name: 'EmployeeAuthentication' });
  }

  /**
   * Attempts to set the document title using Vue I18n.
   * If the translation is not available (`this.$t('global.application.title') === 'global.application.title'`),
   * it will retry every 300ms until a valid translation is found or the maximum time (3 seconds) is reached.
   */
  private setDocumentTitle(): void {
    let elapsedTime = 0;
    const interval = 300; // Retry interval in milliseconds
    const maxTime = 3000; // Maximum wait time in milliseconds (3 seconds)

    /**
     * Function to attempt setting the document title.
     * If the translation is available, it sets the title and clears the interval.
     * Otherwise, it keeps retrying until maxTime is reached.
     */
    const attemptSetTitle = () => {
      const title = this.$t('global.application.title').toString();
      console.log(`Attempting to set title: ${title}`);

      if (title !== 'global.application.title' || elapsedTime >= maxTime) {
        document.title = title;
        console.log(`Title set successfully: ${title}`);
        clearInterval(timer); // Stop retrying
      } else {
        elapsedTime += interval;
      }
    };

    // Start retrying every `interval` milliseconds
    const timer = setInterval(attemptSetTitle, interval);
  }
}
